<template>
  <div class="app-container">
    <div class="top">
      <h3 class="top__title">{{ detail.statusName }}</h3>
      <p
        class="top__text"
        v-if="detail.status === 'SELLER_REJECTED' || detail.status === 'SELLER_REJECT_RECEIVE'"
      >
        {{ detail.remarks }}
      </p>
      <el-button @click="revoke" class="top__btn" size="small" v-if="revokeShow"
        >撤销申请</el-button
      >
      <DialogReturnGoods
        v-if="detail.status === 'WAIT_BUYER_DELIVER'"
        :refundOrderId="$route.params.id"
        @handleReturnGoods="handleReturnGoods"
      />
    </div>
    <div class="detail">
      <div class="detail__box">
        <div class="detail__box--title">退款信息</div>
        <div class="detail__box--content">
          <p class="detail__box--item">退款类型：{{ detail.typeName }}</p>
          <p class="detail__box--item">退款编号：{{ detail.refundNo }}</p>
          <p class="detail__box--item">
            申请时间：{{ detail.createDate | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
          </p>
          <p class="detail__box--item">申请退款金额：¥{{ detail.applyRefundAmount || '0' }}</p>
          <p class="detail__box--item">实际退款金额：¥{{ detail.actualRefundAmount || '0' }}</p>
          <div class="detail__box--box">
            <p class="detail__box--label">退款原因：</p>
            <p class="detail__box--value">
              {{ detail.reason || '/' }}
            </p>
          </div>
          <div class="detail__box--box">
            <p class="detail__box--label">退款说明：</p>
            <p class="detail__box--value">
              {{ detail.description || '/' }}
            </p>
          </div>
          <div class="detail__box--box">
            <p class="detail__box--label">{{ detail.orderDelivery ? '退货凭证' : '退款凭证' }}：</p>
            <div
              class="detail__box--value"
              v-if="detail.orderDelivery ? detail.deliveryImages : detail.applyImages"
            >
              <img
                :src="item"
                class="detail__box--applyImage"
                v-for="(item, index) in detail.orderDelivery
                  ? detail.deliveryImages
                  : detail.applyImages"
                :key="index"
              />
            </div>
            <span v-else>/</span>
          </div>
          <div class="detail__box--valueFlex" v-if="detail.contractInfo">
            <p class="detail__box--label">合同主体：</p>
            <span>{{ detail.contractInfo.merchantTypeName + '-' + detail.contractInfo.contractInfoName }}</span
            >
          </div>
          <div class="detail__box--valueFlex" v-if="detail.orderDelivery">
            <p class="detail__box--label">退货物流：</p>
            <span
              >{{ detail.orderDelivery.companyName }}: {{ detail.orderDelivery.deliveryNo }}</span
            >
            <CheckDeliveryDetail :id="id" type="refund">
              <el-button size="small" class="detail__box--btn"> 查看物流</el-button>
            </CheckDeliveryDetail>
          </div>
        </div>
      </div>
      <div class="detail__box">
        <div class="detail__box--title">基本信息</div>
        <div class="detail__box--content">
          <p class="detail__box--item">
            订单类型：{{ detail.orderType === 'NORMAL' ? '普通订单' : '海淘订单' }}
          </p>
          <p class="detail__box--item">
            订单编号： {{ detail.orderOnRefundVO ? detail.orderOnRefundVO.orderNo : '/' }}
          </p>
          <p class="detail__box--item">
            下单时间：
            <span v-if="detail.orderOnRefundVO">
              {{ detail.orderOnRefundVO.createDate | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
            </span>
            <span v-else>/</span>
          </p>
          <div class="detail__box--box">
            <p class="detail__box--label">收货人：</p>
            <p class="detail__box--value" v-if="basicDetail.orderConsigneeAddressVO">
              {{ basicDetail.orderConsigneeAddressVO.consignee }}，{{
                basicDetail.orderConsigneeAddressVO.mobile
              }}
            </p>
          </div>
          <p class="detail__box--item">支付方式：{{ basicDetail.payTypeName }}</p>
          <p class="detail__box--item">
            交易单号： {{ basicDetail.orderPaymentVO ? basicDetail.orderPaymentVO.tradeNo : '/' }}
          </p>
          <p class="detail__box--item">
            付款时间：{{ basicDetail.payTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
          </p>
          <p class="detail__box--item">
            支付金额：{{
              detail.orderOnRefundVO
                ? `${detail.orderOnRefundVO.payAmount}  （含运费：￥${detail.orderOnRefundVO.deliveryFee}）`
                : '0'
            }}
          </p>
          <div class="detail__box--box">
            <p class="detail__box--label">订单备注：</p>
            <p class="detail__box--value">
              {{ basicDetail.buyerNote || '/' }}
            </p>
          </div>
          <div class="detail__box--valueFlex">
            <p class="detail__box--label">物流信息：</p>
            <div v-if="packageInfo.length">
              <span>{{ packageInfo[0].companyName }}: {{ packageInfo[0].deliveryNo }}</span>
              <CheckDeliveryDetail :id="detail.orderId">
                <el-button size="small" class="detail__box--btn"> 查看物流 </el-button>
              </CheckDeliveryDetail>
            </div>
            <span v-else>/</span>
          </div>
        </div>
      </div>
      <div class="detail__box">
        <div class="detail__box--title"></div>
        <div class="detail__box--content"></div>
      </div>
    </div>
    <div class="table">
      <el-table :data="detail.orderDetails" style="width: 100%" v-loading.body="listLoading">
        <el-table-column label="规格标识" prop="skuId" align="center"> </el-table-column>
        <el-table-column label="缩略图" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.imgUrl" class="table__img" />
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="commodityName" align="center"> </el-table-column>
        <el-table-column label="单价" align="center">
          <template slot-scope="scope">￥{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column label="数量" prop="quantity" align="center"> </el-table-column>
        <el-table-column label="发货状态" align="center">
          <template slot-scope="scope">
              {{ scope.row.deliveryStatusName }}
              <!-- 部分发货需要显示发货数量 -->
              <span v-if="scope.row.deliveryStatus === 'SUB_DELIVERY'">({{ scope.row.deliveryNum }}件)</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getById, getOrderById, getDeliveryInfo, buyerCancel } from '@/api/order/refund';
import CheckDeliveryDetail from '@/components/CheckDeliveryDetail';
import DialogReturnGoods from '@/components/views/order/DialogReturnGoods';
export default {
  components: {
    CheckDeliveryDetail,
    DialogReturnGoods,
  },
  data() {
    return {
      id: '',
      detail: { id: null },
      packageInfo: {},
      basicDetail: {},
      listLoading: true,
      detailLoading: true,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      revokeShow: false,
    };
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      const id = this.$route.params.id;
      this.id = id;
      this.detailLoading = true;
      const { data } = await getById(id);
      await getOrderById(data.orderId).then((response) => {
        this.basicDetail = response.data;
      });
      await getDeliveryInfo(data.orderId).then((response) => {
        this.packageInfo = response.data;
      });
      data.orderDetails.forEach((item, index) => {
        this.basicDetail.orderDetailVOList.forEach((order, index2) => {
          if (item.skuId === order.skuId) {
            data.orderDetails[index].deliveryFlag = this.basicDetail.orderDetailVOList[
              index2
            ].deliveryFlag;
          }
        });
      });
      this.detail = data;
      this.detailLoading = false;
      this.listLoading = false;
      // 能否撤销
      this.revokeShowFun(this.detail.type, this.detail.status, this.detail.orderDetails);
    },
    handleReturnGoods() {
      this.fetchDetail();
    },
    revoke() {
      this.$confirm('是否撤销申请?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          buyerCancel(this.$route.params.id).then((res) => {
            if (res.code === '0') {
              this.$message({
                type: 'success',
                message: '成功!',
              });
              this.fetchDetail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    revokeShowFun(type, status, list) {
      let flag = false;
      let deliveryFlag = true;
      for (const i of list) {
        if (i.deliveryFlag === '0') {
          deliveryFlag = false;
          break;
        }
      }
      if (
        type === 'REFUND_ONLY' &&
        deliveryFlag &&
        (status === 'WAIT_SELLER_AUDIT' || status === 'WAIT_BUYER_DELIVER')
      ) {
        flag = true;
      }
      if (
        type === 'REFUND_RETURN' &&
        (status === 'WAIT_SELLER_AUDIT' || status === 'WAIT_BUYER_DELIVER')
      ) {
        flag = true;
      }
      this.revokeShow = flag;
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.app-container {
  width: 1180px;
  color: #333;
  margin-bottom: 60px;
}

.top {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-top: 4px solid #AB0033;
  margin-bottom: 20px;
  &__title {
    color: #AB0033;
    font-size: 20px;
    line-height: 30px;
  }
  &__text {
    color: #666;
    line-height: 18px;
  }
  &__btn {
    margin-top: 20px;
    margin-right: 20px;
    min-width: 80px;
    color: #AB0033;
    border: 1px solid #AB0033;
  }
}

.detail {
  display: flex;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  &__box {
    width: 393px;
    &:first-of-type {
      border-right: 1px solid #e5e5e5;
    }
    &--title {
      font-size: 14px;
      color: #222;
      background-color: #f3f3f3;
      padding-left: 20px;
      height: 40px;
      line-height: 40px;
    }
    &--content {
      padding: 20px;
    }
    &--item {
      color: #222;
      line-height: 18px;
      margin-bottom: 10px;
    }
    &--box {
      color: #222;
      display: flex;
      line-height: 18px;
      margin-bottom: 10px;
    }
    &--label {
      min-width: 60px;
    }
    &--applyImage {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
    &--btn {
      margin-left: 10px;
      color: #AB0033;
      border: 1px solid #AB0033;
    }
    &--valueFlex {
      display: flex;
      align-items: center;
    }
  }
}

.table {
  border: 1px solid #e5e5e5;
  .el-table {
    font-size: 12px;
    color: #333;
    /deep/ th {
      padding: 8px 0;
      color: #333;
      background-color: #f8f8f8 !important;
      border-color: #e5e5e5;
      font-weight: normal;
    }
    /deep/ .el-table__row {
      height: 110px;
      td {
        border-bottom: 1px dashed #e5e5e5;
      }
    }
    .table__img {
      width: 70px;
      height: 70px;
    }
  }
}
</style>
